<template>
  <ul ref="rootEl" class="products wrapper">
    <li
      v-for="solution in content.solutions"
      ref="product"
      :key="solution.url"
      class="products__item"
    >
      <RouterLink :to="solution.url" class="products__item__link p-m">
        <img :src="solution.content.logo.url" :alt="solution.slug" />
      </RouterLink>
    </li>
  </ul>
</template>

<script setup lang="ts">
import gsap from 'gsap'
import { onMounted, ref } from 'vue'

import { onAppear } from '@/utils/motion'

import type { PropType } from 'vue'

// import type { Picture } from '@/types'

interface Products {
  solutions: {
    content: {
      logo: {
        url: string
      }
    }
    slug: string
    url: string
  }[]
}

defineProps({
  content: {
    type: Object as PropType<Products>,
    required: true,
  },
})

const rootEl = ref<HTMLElement | null>(null)

onMounted(() => {
  if (!rootEl.value) {
    return
  }

  gsap.set(rootEl.value, { opacity: 0 })

  onAppear(rootEl.value, () => {
    const els = rootEl.value?.querySelectorAll('li') || []
    const tl = gsap.timeline({
      onComplete: () => {
        gsap.set(els, { clearProps: 'all' })
      },
    })

    tl.set(rootEl.value, { clearProps: 'opacity' }).from(els, {
      opacity: 0,
      y: 20,
      stagger: 0.05,
      duration: 1,
    })
  })
})
</script>

<style lang="scss" scoped>
$bp: m;

.products {
  display: none;
  list-style: none;
  margin-top: 0;
  margin-bottom: 0;

  @include mq($bp) {
    display: flex;
    justify-content: space-between;
  }
}

.products__item__link {
  display: flex;
  align-items: center;
  width: 100%;
  padding-right: $spacing;
  padding-left: $spacing;
  transition: opacity 0.2s ease-out;

  .products__item:first-child & {
    padding-left: 0;
  }

  .products__item:last-child & {
    padding-right: 0;
  }

  .products:hover & {
    opacity: 0.2;

    &:hover {
      opacity: 1;
    }
  }

  img {
    display: block;
    width: auto;
    height: 3.2rem;
    margin: 0 auto;
  }
}
</style>
